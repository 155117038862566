import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const ArtOfLivingMobile = loadable(() => import('../../components/ArtOfLiving/ArtOfLivingMobile/ArtOfLivingMobile'))
const ArtOfLivingDefault = loadable(() => import('../../components/ArtOfLiving/ArtOfLivingDef/ArtOfLivingDef'))
const ArticleModal = loadable(() => import('../../components/ArtOfLiving/ArticleModal/ArticleModal'))
const SEO = loadable(() => import('../../components/SEO/SEO'))
import { Article, ArticleNode } from '../../shared/types'

import './ArtOfLiving.scss'

const ArtOfLivingTemp = ({ pageContext: { metaData } }: any): JSX.Element => {
  const EMPTY_CONTAINER_ID = 'containers-content-alp-articles'
  const [selectedArticle, setSelectedArticle] = useState<any>(null)
  const [isArtModalShown, showArtOfLivingModal] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState(false)
  const articlesQuery = useStaticQuery(graphql`
    query allArtOfLiving {
      allSitePage(filter: { context: { category: { eq: "articles" } } }) {
        edges {
          node {
            path
            context {
              data {
                id
                slug
                title
                link
                image
                seoTitle
                seoDescription
                headingTag
                description
                subCategory
                datePublished
                altText
                components {
                  description
                  id
                  image
                  video
                  order
                  title
                  image_alt_text
                }
              }
            }
            remoteImage {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const getArticles = (): ArticleNode[] | [] => {
    const articles = articlesQuery.allSitePage.edges.reduce((acc: any, item: any) => {
      acc.push({
        data: item.node.context.data,
        path: item.node.path,
        remoteImage: item.node?.remoteImage
      })
      return acc
    }, [])

    if (articles[0].data.id === EMPTY_CONTAINER_ID) {
      return []
    }

    return articles
  }

  const showArticleModal = (article: Article) => {
    window.history.pushState('', '', `/the-art-of-living/article/${article.slug}/`)
    setSelectedArticle(article)
    showArtOfLivingModal(!isArtModalShown)
  }

  const closeArticleModal = () => {
    window.history.pushState('', '', '/the-art-of-living/')
    setSelectedArticle(null)
    showArtOfLivingModal(false)
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    handleResize() // Initial check
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const search = typeof window && window.location.search
    if (search) {
      const article = getArticles().filter((article: any) => article.data.slug === search.slice(1, -1))[0]

      if (!article) {
        return
      }

      if (article.data.link) {
        const link = document.createElement('a')
        link.target = '_blank'
        link.href = article.data.link
        link.click()
      } else {
        showArticleModal(article.data)
      }
    }
  }, [typeof window !== 'undefined' ? window.location.search : ''])

  const seoTitle = (): string => {
    const defaultPageTitle = 'The Art of Living - Ayala Land Premier'

    if (selectedArticle) {
      const { seoTitle, title } = selectedArticle
      return seoTitle || title || metaData?.title || defaultPageTitle
    }

    return metaData?.title || defaultPageTitle
  }

  const seoDescription = (): string | undefined => {
    if (selectedArticle) {
      const { seoDescription, description } = selectedArticle
      return seoDescription || description || metaData?.description
    }

    return metaData?.description
  }

  return (
    <Layout>
      <SEO title={seoTitle()} description={seoDescription()} image={selectedArticle?.image || getArticles()[0]?.data?.image || ''} />
      <div className="aol-wrapper">
        {!getArticles()?.length && <h3 className="aol-wrapper__empty-text">No data to display.</h3>}

        <ArticleModal
          {...{
            showArtOfLivingModal,
            isArtModalShown,
            selectedArticle,
            closeModal: () => closeArticleModal()
          }}
        />

        {isMobile ? (
          <div className="aol-wrapper__content--mobile">
            <ArtOfLivingMobile
              {...{
                articles: getArticles()
              }}
            />
          </div>
        ) : (
          <div className="aol-wrapper__content--desktop">
            <ArtOfLivingDefault
              {...{
                articles: getArticles(),
                selectArticle: (selectedArticle: Article) => showArticleModal(selectedArticle)
              }}
            />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default ArtOfLivingTemp
